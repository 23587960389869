export const Colors = {
    // White Colors
    white: '#ffffff',
    lightWhite: '#c1c5c8',

    // Black Colors
    black: '#000000',
    lightBlack: '#333333',

    // Gray Colors
    grey: '#393a3d',
    darkGrey: '#76818d',
    darkestGray: '#a0a0a0',
    darkerGray: '#666666',
    darkGray: '#404040',
    gray: '#DADADA',
    lighterGray: '#D9D9D9',
    lightGray: '#e5e5e5',
    lightestGray: '#F3F3F3',
    legalGray: '#757575',
    infoGray: '#eceef1',
    gray1: '#D4D7DC',
    gray2: '#9A9A9A',
    gray3: '#C1C1C1',

    // Other Colors
    lightRed: '#fd5653',
    blue: '#193048',
    lightBlue: '#193048',
    linkBlue: '#0077C5',
    parrotGreen: '#00C312',
    lightGreen: '#1B9988',
    green: '#0E8900',
    darkGreen: '#2ba01c',
    orange: '#EB5600',
    progressBarColor: '#58DB00',
    lightestBlue: '#F4F5F8',
};
