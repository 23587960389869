import { all } from 'redux-saga/effects';
import loginSaga, { logoutWatcher as logoutSaga } from './loginSaga';
import signupSaga from './signupSaga';
import verifySaga from './verificationSaga';
import resetPasswordSaga from './resetPasswordSaga';
import changePasswordSaga from './changePasswordSaga';
import examsSaga from './examsSaga';
import profileSaga from './profileSaga';
import usersSaga from './usersSaga';

export default function* rootSaga() {
    yield all([
        loginSaga(),
        signupSaga(),
        verifySaga(),
        resetPasswordSaga(),
        changePasswordSaga(),
        examsSaga(),
        profileSaga(),
        logoutSaga(),
        usersSaga()
    ]);
}
