import {
    take, put, call, fork, cancel, cancelled
} from 'redux-saga/effects';
import history from '../history';
import { apiPost } from '../utils';
import Storage from '../utils/storage';
import { ACTION_TYPES, API_ROUTES } from '../constants';
import { ROUTE_URLS } from '../routes';
import { logout as logoutAction } from '../actions';

const {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR
} = ACTION_TYPES.LOGIN;

const {
    LOGOUT
} = ACTION_TYPES.LOGOUT;

const {
    user: USER_API
} = API_ROUTES;

const loginApi = credentials => apiPost(
    USER_API.login,
    credentials,
    false
)
    .then(response => response.headers)
    .catch(error => { throw error; });

const sanitizeToken = headers => {
    const token = headers.authorization;
    const expiry = headers.expires;
    return {
        token,
        expiry
    };
};

function* logout() {
    yield put(logoutAction());

    Storage.removeItem('token');

    history.push(ROUTE_URLS.login);
}

function* loginFlow(credentials) {
    let token = null;
    try {
        let headers = yield call(loginApi, credentials);
        headers = yield call(sanitizeToken, headers);
        token = headers.token;

        yield put({ type: LOGIN_SUCCESS, token });

        yield call(Storage.setItem, 'token', headers.token);
        yield call(Storage.setItem, 'expiry', headers.expiry);
        yield call(history.push, '/');
    } catch (error) {
        if (error.response) {
            yield put({ type: LOGIN_ERROR, error: error.response });
        }
    } finally {
        if (yield cancelled()) {
            history.push(ROUTE_URLS.login);
        }
    }
    return token;
}

export default function* loginWatcher() {
    while (true) {
        const { credentials } = yield take(LOGIN_REQUEST);
        const task = yield fork(loginFlow, credentials);

        const action = yield take([LOGOUT, LOGIN_ERROR]);

        if (action.type === LOGOUT) yield cancel(task);
        yield call(logout);
    }
}

export function* logoutWatcher() {
    yield take(LOGOUT);
    yield call(logout);
}
