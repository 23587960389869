import { ACTION_TYPES } from '../constants';

const {
    PROFILE_REQUEST,
    ADD_COURSE_PROGRESS,
    UPDATE_COURSE_PROGRESS
} = ACTION_TYPES.PROFILE;

export const profileRequest = () => ({
    type: PROFILE_REQUEST,
});

export const addCourseProgress = data => ({
    type: ADD_COURSE_PROGRESS,
    data
});

export const updateCourseProgress = data => ({
    type: UPDATE_COURSE_PROGRESS,
    data
});
