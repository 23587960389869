// LOGIN ACTIONS
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';

// USER ACTIONS
const USER_SET = 'AUTH_REQUEST';
const USER_UNSET = 'AUTH_REQUEST';

// SIGNUP ACTIONS
const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
const SIGNUP_ERROR = 'SIGNUP_ERROR';

// VERIFICATION ACTIONS
const VERIFICATION_REQUEST = 'VERIFICATION_REQUEST';
const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
const VERIFICATION_ERROR = 'VERIFICATION_ERROR';

// RESET PASSWORD ACTIONS
const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

// CHANGE PASSWORD ACTIONS
const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

// EXAMS ACTIONS
const EXAMS_REQUEST = 'EXAMS_REQUEST';
const EXAMS_SUCCESS = 'EXAMS_SUCCESS';
const EXAMS_ERROR = 'EXAMS_ERROR';

// PROFILE ACTIONS
const PROFILE_REQUEST = 'PROFILE_REQUEST';
const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
const PROFILE_ERROR = 'PROFILE_ERROR';
const ADD_COURSE_PROGRESS = 'ADD_COURSE_PROGRESS';
const UPDATE_COURSE_PROGRESS = 'UPDATE_COURSE_PROGRESS';

// USERS ACTIONS
const USERS_REQUEST = 'USERS_REQUEST';
const USERS_SUCCESS = 'USERS_SUCCESS';
const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
const USERS_ERROR = 'USERS_ERROR';
const SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST';
const SEARCHED_USER_SUCCESS = 'SEARCHED_USER_SUCCESS';

// LOGOUT ACTIONS
const LOGOUT = 'LOGOUT';

export const ACTION_TYPES = {
    LOGIN: {
        LOGIN_SUCCESS,
        LOGIN_REQUEST,
        LOGIN_ERROR
    },
    USER: {
        USER_SET,
        USER_UNSET
    },
    SIGNUP: {
        SIGNUP_REQUEST,
        SIGNUP_SUCCESS,
        SIGNUP_ERROR
    },
    VERIFICATION: {
        VERIFICATION_REQUEST,
        VERIFICATION_SUCCESS,
        VERIFICATION_ERROR
    },
    RESET_PASSWORD: {
        RESET_PASSWORD_REQUEST,
        RESET_PASSWORD_SUCCESS,
        RESET_PASSWORD_ERROR
    },
    CHANGE_PASSWORD: {
        CHANGE_PASSWORD_REQUEST,
        CHANGE_PASSWORD_SUCCESS,
        CHANGE_PASSWORD_ERROR
    },
    EXAMS: {
        EXAMS_REQUEST,
        EXAMS_SUCCESS,
        EXAMS_ERROR
    },
    PROFILE: {
        PROFILE_REQUEST,
        PROFILE_SUCCESS,
        PROFILE_ERROR,
        ADD_COURSE_PROGRESS,
        UPDATE_COURSE_PROGRESS
    },
    USERS: {
        USERS_REQUEST,
        USERS_SUCCESS,
        USERS_ERROR,
        UPDATE_USER_REQUEST,
        SEARCH_USER_REQUEST,
        SEARCHED_USER_SUCCESS
    },
    LOGOUT: {
        LOGOUT
    }
};
