import React from 'react';
import styled from 'styled-components';
import { Colors } from '../utils/colors';

const FaqContentList = styled.ol`
  list-style: decimal inside none;

  > li {
    font-family: 'Metropolis-Light', sans-serif;
    font-weight: lighter;
    line-height: 24px;
    
    > a {
      color: ${Colors.linkBlue};
      text-decoration: none;
      
      &:hover{
        text-decoration: underline;
      }
    }
  }
`;

const accountFaqs = [
    {
        id: 0,
        title: 'How do I reset my password or recover my user ID?',
        content: (
            <>
                <FaqContentList>
                    <li>
                        Go to
                        {' '}
                        <a
                            href="https://qbglobaltraining.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            qbglobaltraining.com
                        </a>
                    </li>
                    <li>Click on &quot;I forgot my UserID or Password&quot;</li>
                    <li>Enter your email address and we’ll send you details of your account</li>
                </FaqContentList>
            </>
        ),
    },
];

const examFaqs = [
    {
        id: 1,
        title: 'Why should I be doing this training?',
        content: (
            <>
                This training is designed specifically to show you how to get the most out of
                QuickBooks Online, so you can manage your clients, your workflow and more.
            </>
        )
    },
    {
        id: 2,
        title: 'If I am already familiar with QuickBooks Online, '
          + 'do I still need to take the training before taking the exam?',
        content: (
            <>
                All training provided is optional. You can go directly to the certification exam
                if you choose.
            </>
        )
    },
    {
        id: 3,
        title: 'Do I need to score a certain percentage on the exam to pass?',
        content: (
            <>
                You will need 80% to pass each section and only need to re-take sections not passed.
                Check your work before submitting each section, then submit all sections for
                grading.
                <br />
                <br />
                If you pass an exam section with a score of less than 100%, the
                questions you missed will be displayed.
            </>
        )
    },
    {
        id: 4,
        title: 'How long does the certification process take?',
        content: (
            <>
                QuickBooks Online Certification: The QuickBooks Online Certification exam can take
                up to 2 hours.
                <br />
                <br />
                To help you pass the exam we provide you with training courses that can take up to 4
                hours to complete.
            </>
        )
    },
    {
        id: 5,
        title: 'How many times can I try to pass the Certification exam?',
        content: (
            <>
                You can make up to three attempts to pass the whole exam.
                <br />
                <br />
                If you do not pass in the first three attempts, you are locked out for 30 days.
                When the 30 days are up, you can take the certification exam again.
                <br />
                <br />
                The exam can be finished over more than one sitting. Once you begin the exam, you
                can stop and resume later.
            </>
        )
    },
    {
        id: 6,
        title: 'What happens if I fail to pass a section of the exam?',
        content: (
            <>
                If you fail to pass a section of an exam, you do have the chance to retake it. You
                have a total of three attempts available.
                <br />
                <br />
                We currently DO NOT require you to re-take the questions in a section that were
                answered correctly.
            </>
        )
    },
    {
        id: 7,
        title: 'How do I download certification badge and certificate of completion?',
        content: (
            <>
                You will be able to access your badge and certificate of completion on the platform
                after passing your certification exam.
            </>
        )
    },
    {
        id: 8,
        title: 'What if I think a question or answer is worded incorrectly?',
        content: (
            <>
                We welcome feedback. Please send any suggestions, comments, or errors you see to
                {' '}
                <a href="mailto:eventsROW@intuit.com">
                    eventsROW@intuit.com
                </a>
                <br />
                <br />
                While we review ALL feedback provided and respond to most of it, we are unable to
                respond to any feedback about an exam question or answer.
            </>
        )
    },
];

const supportFaqs = [
    {
        id: 9,
        title: 'Where do I find information related to the topics presented in the training modules'
          + ' of the International Certification Platform?',
        content: (
            <>
                To learn more about the topics discussed during the training, please visit the
                {' '}
                <a
                    href="https://quickbooks.intuit.com/learn-support/global/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Global Learn and Support Page
                </a>
                {' or the '}
                <a
                    href="https://quickbooks.intuit.com/learn-support/global-quickbooks-community
                          /misc/03/community-global"
                    target="_blank"
                    rel="noreferrer"
                >
                    Global Community
                </a>
            </>
        )
    },
    {
        id: 10,
        title: 'Where can I read articles on how to grow my accounting or bookkeeping practice and '
          + 'stay updated with the latest QuickBooks Online product updates?',
        content: (
            <>
                You can read all about it
                {' '}
                <a
                    href="https://quickbooks.intuit.com/learn-support/help-articles
                         /misc/03/help-articles-global"
                    target="_blank"
                    rel="noreferrer"
                >
                    here
                </a>
            </>
        )
    },
    {
        id: 11,
        title: 'How can I get in touch with Intuit QuickBooks customer support?',
        content: (
            <>
                To get in touch with Intuit QuickBooks customer support please visit our
                {' '}
                <a
                    href="https://quickbooks.intuit.com/global/contact/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Contact Page
                </a>
            </>
        )
    },
];

export {
    accountFaqs,
    examFaqs,
    supportFaqs
};
