import React, { Suspense, lazy } from 'react';
import {
    Router, Switch, Route
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Loader } from './components/Loader';
import PrivateRoute from './components/private-route';
import PublicRoute from './components/public-route';
import { PUBLIC_ROUTES, PRIVATE_ROUTES } from './routes';
import store from './store';
import history from './history';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const PageNotFound = lazy(() => import('./components/NotFound/NotFound'));

function App() {
    return (
        <Provider store={store}>
            <ToastContainer />
            <div id="app">
                <Router history={history}>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {PUBLIC_ROUTES.map(({ component: Component, path, exact }) => (
                                <PublicRoute
                                    path={path}
                                    key={path}
                                    exact={exact}
                                >
                                    <Component />
                                </PublicRoute>
                            ))}
                            {PRIVATE_ROUTES.map(({ component: Component, path, exact }) => (
                                <PrivateRoute
                                    path={path}
                                    key={path}
                                    exact={exact}
                                >
                                    <Component />
                                </PrivateRoute>
                            ))}
                            <Route path="*">
                                <PageNotFound />
                            </Route>
                        </Switch>
                    </Suspense>
                </Router>
            </div>
        </Provider>
    );
}

export default App;
