import { ACTION_TYPES } from '../constants';

const {
    CHANGE_PASSWORD_REQUEST
} = ACTION_TYPES.CHANGE_PASSWORD;

export const changePasswordRequest = data => ({
    type: CHANGE_PASSWORD_REQUEST,
    data
});
