import { ACTION_TYPES } from '../constants';

const {
    RESET_PASSWORD_REQUEST
} = ACTION_TYPES.RESET_PASSWORD;

export const resetPasswordRequest = data => ({
    type: RESET_PASSWORD_REQUEST,
    data
});
