import { ACTION_TYPES } from '../constants';

const {
    VERIFICATION_REQUEST
} = ACTION_TYPES.VERIFICATION;

export const verificationRequest = data => ({
    type: VERIFICATION_REQUEST,
    data
});
