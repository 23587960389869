import { toast } from 'react-toastify';

export const objectToQueryParams = params => {
    const paramList = [];
    Object.keys(params).forEach(key => {
        if (params[key] !== undefined && params[key] !== null) {
            paramList.push(`${key}=${encodeURIComponent(params[key])}`);
        }
    });

    return paramList.join('&');
};

export const checkAuthorization = () => {
    const storedToken = localStorage.getItem('token');

    if (storedToken) {
        return true;
    }
    return false;
};

export const displayError = message => toast.error(message, {
    theme: 'colored',
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

export const displaySuccess = message => toast.success(message, {
    theme: 'colored',
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});
