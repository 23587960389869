import { ACTION_TYPES } from '../constants';

const {
    VERIFICATION_REQUEST,
    VERIFICATION_SUCCESS,
    VERIFICATION_ERROR,
} = ACTION_TYPES.VERIFICATION;

const initialState = {
    requesting: false,
    successful: false,
    messages: [],
    errors: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case VERIFICATION_REQUEST:
        return {
            requesting: true,
            successful: false,
            messages: [{ body: 'Sending verification link...', time: new Date() }],
            errors: [],
        };

    case VERIFICATION_SUCCESS:
        return {
            errors: [],
            messages: [{
                body: 'Verification link sent successfully...',
                time: new Date(),
            }],
            requesting: false,
            successful: true,
        };

    case VERIFICATION_ERROR:
        return {
            errors: state.errors.concat([
                action.error.data,
            ]),
            messages: [],
            requesting: false,
            successful: false,
        };
    default:
        return state;
    }
};
