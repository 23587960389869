import {
    call,
    put,
    takeEvery,
    takeLatest,
    select
} from 'redux-saga/effects';
import { ACTION_TYPES, API_ROUTES } from '../constants';
import { apiGet, apiPost, apiPut } from '../utils';

const {
    USERS_REQUEST,
    USERS_SUCCESS,
    USERS_ERROR,
    UPDATE_USER_REQUEST,
    SEARCH_USER_REQUEST,
    SEARCHED_USER_SUCCESS
} = ACTION_TYPES.USERS;

const {
    allUsers: ALL_USERS
} = API_ROUTES;

const allUsersApi = data => apiGet(
    ALL_USERS.users,
    data,
    true
)
    .then(response => response.data)
    .catch(error => { throw error; });

const updateUserApi = ({ data, userId }) => apiPut(
    ALL_USERS.updateUser(userId),
    data,
    true
)
    .then(response => response.data)
    .catch(error => { throw error; });

const searchUserApi = data => apiPost(
    ALL_USERS.searchUser,
    data,
    true
)
    .then(response => response.data)
    .catch(error => { throw error; });

function* usersFlow(action) {
    const { data } = action ?? {};
    if (data) {
        try {
            const allUsersList = yield call(allUsersApi, data);
            yield put({ type: USERS_SUCCESS, users: allUsersList });
        } catch (error) {
            if (error.response) {
                yield put({ type: USERS_ERROR, error: error.response });
            }
        }
    }
}

function* updateUserFlow(action) {
    const { data, userId } = action ?? {};
    if (data) {
        try {
            yield call(updateUserApi, { data, userId });
            const { allUsersReducer: { allUsersInfo } } = yield select();
            yield put({
                type: USERS_REQUEST,
                data: {
                    page: allUsersInfo?.page,
                    size: allUsersInfo?.size
                }
            });
        } catch (error) {
            if (error.response) {
                yield put({ type: USERS_ERROR, error: error.response });
            }
        }
    }
}

function* searchUserFlow(action) {
    const { data } = action ?? {};
    if (data) {
        try {
            const searchedUser = yield call(searchUserApi, data);
            yield put({
                type: SEARCHED_USER_SUCCESS,
                user: searchedUser ? [searchedUser] : []
            });
        } catch (error) {
            if (error.response) {
                yield put({ type: USERS_ERROR, error: error.response });
            }
        }
    }
}

export default function* usersWatcher() {
    yield takeLatest(USERS_REQUEST, usersFlow);
    yield takeEvery(UPDATE_USER_REQUEST, updateUserFlow);
    yield takeEvery(SEARCH_USER_REQUEST, searchUserFlow);
}
