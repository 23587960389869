import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
    password: Yup.string()
        .required('Required'),
    userName: Yup.string().email('Invalid email').required('Required'),
});

export const signupSchema = Yup.object().shape({
    firstName: Yup.string()
        .matches(
            /^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/,
            'Must be a valid name'
        )
        .required('Required'),
    lastName: Yup.string()
        .matches(
            /^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/,
            'Must be a valid name'
        )
        .required('Required'),
    postalCode: Yup.number()
        .typeError('Must be a number')
        .positive('Must be greater than zero')
        .required('Required'),
    numberOfEmployees: Yup.object().required('Required'),
    password: Yup.string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_()!@#$%^&*])(?=.{8,})/,
            // eslint-disable-next-line max-len
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        )
        .required('Required'),
    emailAddress: Yup.string().email('Invalid email').required('Required'),
    company: Yup.string().required('Required'),
    countryOfResidence: Yup.object().required('Required'),
    countryOfBusiness: Yup.array().min(1, 'Select atleast one country'),
    phoneNumber: Yup.string()
        .matches(
            // eslint-disable-next-line max-len
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            'Phone number is not valid'
        )
        .required('Phone number is required'),
    confirmedPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords are not the same!')
        .required('Required')

});

export const newPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            // eslint-disable-next-line max-len
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        )
        .required('Please Enter your password'),
    confirmedPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords are not the same!')
        .required('Required')
});

export const confirmMailSchema = Yup.object().shape({
    emailAddress: Yup.string().email('Invalid email').required('Required')
});

export const editedUserSchema = Yup.object().shape({
    firstName: Yup.string()
        .matches(
            /^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/,
            'Must be a valid name'
        )
        .required('Required'),
    lastName: Yup.string()
        .matches(
            /^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/,
            'Must be a valid name'
        )
        .required('Required'),
    postalCode: Yup.number()
        .typeError('Must be a number')
        .positive('Must be greater than zero')
        .required('Required'),
    numberOfEmployees: Yup.object().required('Required'),
    emailAddress: Yup.string().email('Invalid email').required('Required'),
    company: Yup.string().required('Required'),
    countryOfResidence: Yup.object().required('Required'),
    countryOfBusiness: Yup.array().min(1, 'Select atleast one country'),
    phoneNumber: Yup.string()
        .matches(
            // eslint-disable-next-line max-len
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            'Phone number is not valid'
        )
        .required('Phone number is required'),
});
