export const getFormatCoursesProgress = profile => {
    const { courseProgress } = profile || {};
    if (!courseProgress) return null;

    const formattedCoursesProgress = {};
    courseProgress.forEach(course => {
        formattedCoursesProgress[course.courseName] = { ...course };
    });

    return formattedCoursesProgress;
};
