import {
    put, call, takeLatest, takeEvery
} from 'redux-saga/effects';
import { apiGet, apiPost, apiPut } from '../utils';
import { ACTION_TYPES, API_ROUTES } from '../constants';
import Storage from '../utils/storage';
import { getFormatCoursesProgress } from '../utils/functions/profile';

const {
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
    PROFILE_ERROR,
    ADD_COURSE_PROGRESS,
    UPDATE_COURSE_PROGRESS
} = ACTION_TYPES.PROFILE;

const {
    user: USER_API
} = API_ROUTES;

const profileApi = data => apiGet(
    USER_API.profile,
    data,
    true
)
    .then(response => response.data)
    .catch(error => { throw error; });

const addCourseProgressApi = (userId, data) => apiPut(
    USER_API.addCourseProgress(userId),
    data,
    true
);

const updateCourseProgressApi = (userId, courseId, data) => apiPost(
    USER_API.updateCourseProgress(userId, courseId),
    data,
    true
);

function* profileFlow() {
    const token = Storage.getItem('token');

    const hash = token.split('.')[1];
    const userName = JSON.parse(atob(hash)).sub;
    const data = {
        userName
    };

    try {
        const profile = yield call(profileApi, data);
        const formattedProfile = { ...profile, courseProgress: getFormatCoursesProgress(profile) };
        yield put({ type: PROFILE_SUCCESS, profile: formattedProfile });
    } catch (error) {
        if (error.response) {
            yield put({ type: PROFILE_ERROR, error: error.response });
        }
    }
}

function* addCourseProgress(action) {
    const { userId, courseData } = action.data ?? {};
    if (userId && courseData) {
        try {
            yield call(addCourseProgressApi, userId, courseData);
            yield put({ type: PROFILE_REQUEST });
        } catch (error) {
            // eslint-disable-next-line
            console.error('Error Occured : ', error);
        }
    }
}

function* updateCourseProgress(action) {
    const { userId, courseId, courseData } = action.data ?? {};
    if (userId && courseId && courseData) {
        try {
            yield call(updateCourseProgressApi, userId, courseId, courseData);
            yield put({ type: PROFILE_REQUEST });
        } catch (error) {
            // eslint-disable-next-line
            console.error('Error Occured : ', error);
        }
    }
}

export default function* profileWatcher() {
    yield takeLatest(PROFILE_REQUEST, profileFlow);
    yield takeEvery(ADD_COURSE_PROGRESS, addCourseProgress);
    yield takeEvery(UPDATE_COURSE_PROGRESS, updateCourseProgress);
}
