import {
    put, call, takeLatest
} from 'redux-saga/effects';
import { apiPost, objectToQueryParams } from '../utils';
import { ACTION_TYPES, API_ROUTES } from '../constants';

const {
    EXAMS_REQUEST,
    EXAMS_SUCCESS,
    EXAMS_ERROR
} = ACTION_TYPES.EXAMS;

const {
    exam: EXAM_API
} = API_ROUTES;

const examsApi = data => apiPost(
    `${EXAM_API.exams}?${objectToQueryParams(data)}`,
    true
)
    .then(response => response.data)
    .catch(error => { throw error; });

function* examsFlow(action) {
    const { data } = action;
    try {
        const exams = yield call(examsApi, data);
        yield put({ type: EXAMS_SUCCESS, exams });
    } catch (error) {
        if (error.response) {
            yield put({ type: EXAMS_ERROR, error: error.response });
        }
    }
}

export default function* examsWatcher() {
    yield takeLatest(EXAMS_REQUEST, examsFlow);
}
