import { call, put, takeLatest } from 'redux-saga/effects';

import { ACTION_TYPES, API_ROUTES } from '../constants';
import { apiGet, objectToQueryParams } from '../utils';

const {
    VERIFICATION_REQUEST,
    VERIFICATION_SUCCESS,
    VERIFICATION_ERROR,
} = ACTION_TYPES.VERIFICATION;

const {
    user: USER_API
} = API_ROUTES;

const verify = data => apiGet(
    `${USER_API.reverifyUser}?${objectToQueryParams(data)}`,
    false
)
    .then(response => response.headers)
    .catch(error => { throw error; });

function* verifyFlow(action) {
    try {
        const { data } = action;

        const response = yield call(verify, data);

        yield put({ type: VERIFICATION_SUCCESS, response });
    } catch (error) {
        if (error.response) {
            yield put({ type: VERIFICATION_ERROR, error: error.response });
        }
    }
}

export default function* verifyWatcher() {
    yield takeLatest(VERIFICATION_REQUEST, verifyFlow);
}
