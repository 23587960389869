export const API_ROUTES = {
    user: {
        login: '/user/login',
        getUsers: '/user/all',
        changePassword: '/user/changePassword',
        profile: '/user/profile',
        signup: '/user/register',
        updateUser: '/user/update',
        verifyUser: '/user/verify',
        reverifyUser: '/user/reverify',
        forgotPassword: '/user/forgotpass',
        addExamToUser: (id, examId) => `/user/${id}/examDetails/${examId}`,
        addCourseProgress: userId => `/user/${userId}/courseProgress/`,
        updateCourseProgress: (userId, courseId) => `/user/${userId}/courseProgress/${courseId}`,
    },
    exam: {
        exams: '/exams',
    },
    allUsers: {
        users: 'user/all',
        updateUser: userId => `user/update?userId=${userId}`,
        searchUser: 'user/search'
    }
};
