import { ACTION_TYPES } from '../constants';

const {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR
} = ACTION_TYPES.LOGIN;

const {
    LOGOUT
} = ACTION_TYPES.LOGOUT;

const initialState = {
    requesting: false,
    successful: false,
    token: null,
    messages: [],
    errors: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case LOGIN_REQUEST:
        return {
            requesting: true,
            successful: false,
            token: null,
            messages: [{ body: 'Logging in...', time: new Date() }],
            errors: [],
        };

    case LOGIN_SUCCESS:
        return {
            errors: [],
            messages: [],
            requesting: false,
            token: action.token,
            successful: true,
        };

    case LOGIN_ERROR:
        return {
            errors: state.errors.concat([
                action.error,
            ]),
            messages: [],
            token: null,
            requesting: false,
            successful: false,
        };

    case LOGOUT:
        return {
            ...state,
            token: null
        };

    default:
        return state;
    }
};
