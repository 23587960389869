import React from 'react';
import PropTypes from 'prop-types';
import {
    Route,
    Redirect
} from 'react-router-dom';
import { ROUTE_URLS } from '../routes';
import { checkAuthorization } from '../utils';

const PrivateRoute = ({ children, ...rest }) => {
    const isAuthenticated = checkAuthorization();
    return (
        <Route
            {...rest}
            render={
                ({ location }) => (
                    isAuthenticated ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: ROUTE_URLS.login,
                                state: { from: location }
                            }}
                        />
                    ))
            }
        />
    );
};

PrivateRoute.propTypes = {
    children: PropTypes.element.isRequired,
};

export default PrivateRoute;
