import axios from 'axios';
import Storage from './storage';
import { SERVER_URL } from '../constants';
import { objectToQueryParams } from './utils';

require('es6-promise').polyfill();

export const getHeaders = (useToken, local) => {
    const headers = {};
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (useToken && local && Storage.getItem('token')) {
        headers.Authorization = Storage.getItem('token');
    }
    if (useToken && !local) {
        headers.Authorization = `Bearer ${params.q}`;
    }
    return headers;
};

export const apiPatch = (requestUrl, postData, useToken = true, local = true) => axios({
    method: 'patch',
    url: `${SERVER_URL}${requestUrl}`,
    headers: getHeaders(useToken, local),
    data: postData,
});

export const apiPut = (requestUrl, postData, useToken = true, local = true) => axios({
    method: 'put',
    url: `${SERVER_URL}${requestUrl}`,
    headers: getHeaders(useToken, local),
    data: postData,
});

export const apiPost = (requestUrl, postData, useToken = true, config = {}, local = true) => axios({
    method: 'post',
    url: `${SERVER_URL}${requestUrl}`,
    headers: getHeaders(useToken, local),
    data: postData,
    ...config
});

export const apiDelete = (requestUrl, useToken = true, local = true) => axios({
    method: 'delete',
    url: `${SERVER_URL}${requestUrl}`,
    headers: getHeaders(useToken, local)
});

export const apiGet = (requestUrl, params = {}, useToken = true, local = true) => {
    let completeUrl = `${SERVER_URL}${requestUrl}`;
    const queryString = objectToQueryParams(params);

    if (queryString) {
        completeUrl = `${completeUrl}?${queryString}`;
    }

    return axios.get(completeUrl, { headers: getHeaders(useToken, local) });
};
