import { Images } from '../utils/images';

export const modules = [
    {
        id: 0,
        image: Images.Tsheets,
        text: 'Create Company',
        time: '30 mins',
        link: 'https://cdn.proadvisor-training.a.intuit.com/ROW/2022/QBO+IT+Module+1.mp4'
    },
    {
        id: 1,
        image: Images.Settings,
        text: 'Account & Settings',
        time: '30 mins',
        link: 'https://cdn.proadvisor-training.a.intuit.com/ROW/2022/QBO+IT+Module+2.mp4'
    },
    {
        id: 2,
        image: Images.Confirm,
        text: 'Chart of Accounts',
        time: '30 mins',
        link: 'https://cdn.proadvisor-training.a.intuit.com/ROW/2022/QBO+IT+Module+3.mp4'

    },
    {
        id: 3,
        image: Images.SpreadSheet,
        text: 'Inventory Management',
        time: '30 mins',
        link: 'https://cdn.proadvisor-training.a.intuit.com/ROW/2022/QBO+IT+Module+4.mp4'
    },
    {
        id: 4,
        image: Images.Vendor,
        text: 'Supplier & Purchase',
        time: '30 mins',
        link: 'https://cdn.proadvisor-training.a.intuit.com/ROW/2022/QBO+IT+Module+5.mp4'
    },
    {
        id: 5,
        image: Images.Payment,
        text: 'Sales & Payments',
        time: '30 mins',
        link: 'https://cdn.proadvisor-training.a.intuit.com/ROW/2022/QBO+IT+Module+6.mp4'
    },
    {
        id: 6,
        image: Images.Reports,
        text: 'Reports',
        time: '30 mins',
        link: 'https://cdn.proadvisor-training.a.intuit.com/ROW/2022/QBO+IT+Module+7.mp4'
    },
    {
        id: 7,
        image: Images.PiggyBank,
        text: 'Online Banking',
        time: '30 mins',
        link: 'https://cdn.proadvisor-training.a.intuit.com/ROW/2022/QBO+Module+8+v3.mp4'
    },
];
