import React from 'react';
import { Images } from '../../utils/images';
import { SpinnerWrapper, StyledSpinner } from './styled';

const Loader = () => (
    <SpinnerWrapper>
        <StyledSpinner src={Images.Spinner} />
    </SpinnerWrapper>
);

export default Loader;
