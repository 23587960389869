import { ACTION_TYPES } from '../constants';

const {
    EXAMS_REQUEST,
    EXAMS_SUCCESS,
    EXAMS_ERROR
} = ACTION_TYPES.EXAMS;

const initialState = {
    requesting: false,
    successful: false,
    exams: null,
    messages: [],
    errors: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case EXAMS_REQUEST:
        return {
            requesting: true,
            successful: false,
            exams: null,
            messages: [{ body: 'Fetching exams...', time: new Date() }],
            errors: [],
        };

    case EXAMS_SUCCESS:
        return {
            errors: [],
            messages: [],
            requesting: false,
            exams: action.exams,
            successful: true,
        };

    case EXAMS_ERROR:
        return {
            errors: state.errors.concat([
                action.error,
            ]),
            messages: [],
            exams: null,
            requesting: false,
            successful: false,
        };

    default:
        return state;
    }
};
