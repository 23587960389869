import { call, put, takeLatest } from 'redux-saga/effects';

import { ACTION_TYPES, API_ROUTES } from '../constants';
import history from '../history';
import { apiPost } from '../utils';
import { ROUTE_URLS } from '../routes';

const {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
} = ACTION_TYPES.CHANGE_PASSWORD;

const {
    user: USER_API
} = API_ROUTES;

const changePasswordApi = data => apiPost(
    USER_API.changePassword,
    data,
    true,
    {},
    false
)
    .then(response => response.headers)
    .catch(error => { throw error; });

function* changePasswordFlow(action) {
    try {
        const { data } = action;

        const response = yield call(changePasswordApi, data);

        yield put({ type: CHANGE_PASSWORD_SUCCESS, response });
        yield call(history.push, ROUTE_URLS.login);
    } catch (error) {
        if (error.response) {
            yield put({ type: CHANGE_PASSWORD_ERROR, error: error.response });
        }
    }
}

export default function* changePasswordWatcher() {
    yield takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordFlow);
}
