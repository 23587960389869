/* eslint-disable global-require */
const Images = {
    Annoucement: require('../images/announcement.png').default,
    Badge: require('../images/badge.png').default,
    GettingStarted: require('../images/getting_started.png').default,
    Logo: require('../images/logo.png').default,
    ManagingTime: require('../images/managing_time.png').default,
    RecordingTime: require('../images/recording_time.png').default,
    Signature: require('../components/Certificate/assets/imgs/signature.png').default,
    Spinner: require('../images/spinner.svg').default,
    TimeSubscription: require('../images/time_subscription.png').default,
    SignupImage: require('../images/signup-side-image.png').default,
    LoginImage: require('../images/login-side-image.png').default,
    PiggyBank: require('../images/bank-pig.svg').default,
    Payment: require('../images/payments.svg').default,
    Confirm: require('../images/confirm.svg').default,
    Reports: require('../images/reports.svg').default,
    Settings: require('../images/settings.svg').default,
    SpreadSheet: require('../images/spreadsheet.svg').default,
    Tsheets: require('../images/tsheets.svg').default,
    Vendor: require('../images/vendor.svg').default,
    EyeIcon: require('../images/eye-icon.svg').default,
    SearchIcon: require('../images/search-icon.svg').default,
};

export { Images };
