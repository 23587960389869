import { lazy } from 'react';

export const BASE_ROUTE = '/';

export const ROUTE_URLS = {
    dashboard: `${BASE_ROUTE}`,
    login: `${BASE_ROUTE}login/`,
    signup: `${BASE_ROUTE}signup/`,
    pageNotFound: `${BASE_ROUTE}404/`,
    somethingWentWrong: `${BASE_ROUTE}error/`,
    resetPassword: `${BASE_ROUTE}reset-password/`,
    confirmMail: `${BASE_ROUTE}confirm-mail/`,
    signupSuccess: `${BASE_ROUTE}signup-success/`,
    profile: `${BASE_ROUTE}my-profile/`,
    settingsPage: `${BASE_ROUTE}settings/`,
    allUsersPage: `${BASE_ROUTE}users/`,
};

export const PUBLIC_ROUTES = [
    {
        path: ROUTE_URLS.login,
        component: lazy(() => import('./containers/auth/Login/Login')),
        exact: true
    },
    {
        path: ROUTE_URLS.signup,
        component: lazy(() => import('./containers/auth/Signup/Signup')),
        exact: true
    },
    {
        path: ROUTE_URLS.signupSuccess,
        component: lazy(() => import('./containers/auth/SignupSuccess/SignupSuccess')),
        exact: true
    },
    {
        path: ROUTE_URLS.resetPassword,
        component: lazy(() => import('./containers/auth/ForgotPassword/ForgotPassword')),
        exact: true
    },
    {
        path: ROUTE_URLS.confirmMail,
        component: lazy(() => import('./containers/auth/ConfirmMail/ConfirmMail')),
        exact: true
    }
];

export const PRIVATE_ROUTES = [
    {
        path: ROUTE_URLS.dashboard,
        component: lazy(() => import('./containers/Dashboard/Dashboard')),
        exact: true
    },
    {
        path: ROUTE_URLS.settingsPage,
        component: lazy(() => import('./containers/Settings/Settings')),
        exact: true
    },
    {
        path: ROUTE_URLS.profile,
        component: lazy(() => import('./containers/Profile/Profile')),
        exact: true
    },
    {
        path: ROUTE_URLS.allUsersPage,
        component: lazy(() => import('./containers/Users/Users')),
        exact: true
    },
];
