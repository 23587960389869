import {
    ACTION_TYPES,
} from '../constants';

const {
    LOGIN_REQUEST
} = ACTION_TYPES.LOGIN;

const {
    LOGOUT
} = ACTION_TYPES.LOGOUT;

export const loginRequest = credentials => ({
    type: LOGIN_REQUEST,
    credentials
});

export const logout = () => ({
    type: LOGOUT
});
