import { call, put, takeLatest } from 'redux-saga/effects';
import Storage from '../utils/storage';
import { ACTION_TYPES, API_ROUTES } from '../constants';
import { apiGet } from '../utils';

const {
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
} = ACTION_TYPES.RESET_PASSWORD;

const {
    user: USER_API
} = API_ROUTES;

const sendResetPassMail = data => apiGet(
    USER_API.forgotPassword,
    data,
    false
)
    .then(response => response.headers)
    .catch(error => { throw error; });

function* sendResetPassMailFlow(action) {
    try {
        const { data } = action;

        const response = yield call(sendResetPassMail, data);

        yield put({ type: RESET_PASSWORD_SUCCESS, response });
        yield call(Storage.setItem, 'emailAddress', data.emailAddress);
    } catch (error) {
        if (error.response) {
            yield put({ type: RESET_PASSWORD_ERROR, error: error.response });
        }
    }
}

export default function* sendResetPassMailWatcher() {
    yield takeLatest(RESET_PASSWORD_REQUEST, sendResetPassMailFlow);
}
