import { ACTION_TYPES } from '../constants';

const {
    USERS_REQUEST,
    USERS_SUCCESS,
    USERS_ERROR,
    SEARCH_USER_REQUEST,
    SEARCHED_USER_SUCCESS
} = ACTION_TYPES.USERS;

const initialState = {
    requesting: false,
    successful: false,
    allUsersInfo: null,
    searchedUsers: null,
    searchingUsers: false,
    messages: [],
    errors: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case USERS_REQUEST:
        return {
            requesting: true,
            successful: false,
            allUsersInfo: state?.allUsersInfo ?? null,
            messages: [{ body: 'Fetching users...', time: new Date() }],
            errors: [],
        };

    case USERS_SUCCESS:
        return {
            errors: [],
            messages: [],
            requesting: false,
            allUsersInfo: action.users,
            successful: true,
        };

    case USERS_ERROR:
        return {
            errors: state.errors.concat([
                action.error,
            ]),
            messages: [],
            allUsersInfo: null,
            requesting: false,
            successful: false,
        };

    case SEARCH_USER_REQUEST:
        return {
            ...state,
            searchingUsers: true,
        };

    case SEARCHED_USER_SUCCESS:
        return {
            ...state,
            searchingUsers: false,
            searchedUsers: action.user
        };

    default:
        return state;
    }
};
