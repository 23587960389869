import { ACTION_TYPES } from '../constants';

const {
    EXAMS_REQUEST
} = ACTION_TYPES.EXAMS;

export const examsRequest = data => ({
    type: EXAMS_REQUEST,
    data
});
