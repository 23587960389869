import { call, put, takeLatest } from 'redux-saga/effects';

import { ACTION_TYPES, API_ROUTES } from '../constants';
import history from '../history';
import { apiPost } from '../utils';
import { ROUTE_URLS } from '../routes';

const {
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_ERROR,
} = ACTION_TYPES.SIGNUP;

const {
    user: USER_API
} = API_ROUTES;

const signupApi = data => apiPost(
    USER_API.signup,
    data,
    false
)
    .then(response => response.headers)
    .catch(error => { throw error; });

function* signupFlow(action) {
    try {
        const { data } = action;

        const response = yield call(signupApi, data);
        response.emailAddress = data.emailAddress;
        yield put({ type: SIGNUP_SUCCESS, response });
        yield call(history.push, ROUTE_URLS.login);
    } catch (error) {
        if (error.response) {
            yield put({ type: SIGNUP_ERROR, error: error.response });
        }
    }
}

export default function* signupWatcher() {
    yield takeLatest(SIGNUP_REQUEST, signupFlow);
}
