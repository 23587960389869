import { ACTION_TYPES } from '../constants';

const {
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_ERROR,
} = ACTION_TYPES.SIGNUP;

const initialState = {
    requesting: false,
    successful: false,
    messages: [],
    emailAddress: null,
    errors: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SIGNUP_REQUEST:
        return {
            requesting: true,
            successful: false,
            messages: [{ body: 'Signing up...', time: new Date() }],
            emailAddress: null,
            errors: [],
        };

    case SIGNUP_SUCCESS:
        return {
            errors: [],
            messages: [{
                body: `Successfully created account for ${action.response.emailAddress}`,
                time: new Date(),
            }],
            emailAddress: action.response.emailAddress,
            requesting: false,
            successful: true,
        };

    case SIGNUP_ERROR:
        return {
            errors: state.errors.concat([
                action.error.data,
            ]),
            messages: [],
            emailAddress: null,
            requesting: false,
            successful: false,
        };

    default:
        return state;
    }
};
