import { ACTION_TYPES } from '../constants';

const {
    SIGNUP_REQUEST
} = ACTION_TYPES.SIGNUP;

export const signupRequest = data => ({
    type: SIGNUP_REQUEST,
    data
});
