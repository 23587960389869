import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import signupReducer from './signupReducer';
import verificationReducer from './verificationReducer';
import resetPasswordReducer from './resetPasswordReducer';
import changePasswordReducer from './changePasswordReducer';
import examsReducer from './examsReducer';
import profileReducer from './profileReducer';
import allUsersReducer from './usersReducer';

const rootReducer = combineReducers({
    loginReducer,
    signupReducer,
    verificationReducer,
    resetPasswordReducer,
    changePasswordReducer,
    examsReducer,
    profileReducer,
    allUsersReducer
});

export default rootReducer;
