import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  margin: 40px auto;
  text-align: center;
`;

export const StyledSpinner = styled.img`
  filter: invert(33%) sepia(95%) saturate(969%) hue-rotate(80deg) brightness(94%) contrast(102%);
`;
